export class DifficultyIndex {
    static calculate(points: number): number {
        if (points <= 22) {
            return 1;
        }
        else if (points <= 33) {
            return 2;
        }
        else if (points <= 47) {
            return 3;
        }
        else if (points <= 62) {
            return 4;
        }
        else {
            return 5;
        }
    }
}