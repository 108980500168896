import { Router } from "./Router";
import { DataStore } from "./data/DataStore";
import { State } from "./data/State";
import { Renderer } from "./Renderer";
import { Navigator } from "./Navigator";

export class App {
    static router: Router;
    static store: DataStore;
    static state: State;

    static init() {
        App.router = new Router()
            .register(/^#question\/(\d+)$/, App.question)
            .register(/^#result$/, App.result)
            ;
        App.store = DataStore.createDefault();
        App.state = new State();
    }

    static navigate(hash: string) {
        App.router.handle(hash);
    }

    static question(args: RegExpMatchArray) {
        let questionIndex = parseInt(args[1]) - 1;
        if (questionIndex > App.state.answers.length) {
            Navigator.question(App.state.answers.length);
            return;
        }
        console.log('Rendering question ' + questionIndex);
        let question = App.store.questions[questionIndex];
        App.output(Renderer.render(questionIndex, question));
    }

    static result(args: RegExpMatchArray) {
        if (!App.state.isComplete()) {
            Navigator.question(App.state.answers.length);
        }

        App.output(Renderer.result());
    }

    static output(html: string) {
        document.getElementById('content').innerHTML = html;
        App.setupHooks();
    }

    static setupHooks() {
        let reset = document.getElementById('q_reset');
        if (reset != null) {
            reset.addEventListener('click', function () {
                App.state = new State();
                Navigator.question(0);
            });
        }

        let next = document.getElementById('q_next');
        if (next != null) {
            next.addEventListener('click', function () {
                let options = document.getElementsByClassName('option');
                for (let i = 0; i < options.length; i++) {
                    let o = options[i] as HTMLInputElement;
                    if (o.checked) {
                        let qIdx = parseInt(o.name);
                        let oIdx = parseInt(o.value);

                        App.state.setAnswer(qIdx, oIdx);
                        Navigator.question(qIdx + 1);
                    }
                }
            });
        }

        let prev = document.getElementById('q_prev');
        if (prev != null) {
            prev.addEventListener('click', function () {
                let options = document.getElementsByClassName('option');

                if (options.length == 0 && App.state.isComplete()) {
                    Navigator.question(App.state.answers.length - 1);
                    return;
                }

                for (let i = 0; i < options.length; i++) {
                    let o = options[i] as HTMLInputElement;
                    if (o.checked) {
                        let qIdx = parseInt(o.name);
                        if (qIdx == 0) {
                            return;
                        }

                        let oIdx = parseInt(o.value);

                        App.state.setAnswer(qIdx, oIdx);
                        Navigator.question(qIdx - 1);
                    }
                }
            });
        }
    }
}
