import { Question } from "./data/Question";
import { App } from "./App";
import { DifficultyIndex } from "./data/DifficultyIndex";

export class Renderer {
    static render(qIdx: number, question: Question): string {
        var template = '';

        template += `<h4>${qIdx+1}. ${question.title}</h4>`;
        let i = 0;
        template += '<form action="#">';
        question.options.forEach(x => {
            let isChecked = App.state.answers.length > qIdx
                ? App.state.answers[qIdx] == i
                : x.default;

            let checked = isChecked
                ? ' checked'
                : null;

            template += `<p><label><input class="option" type="radio" name="${qIdx}" value="${i}"${checked}><span class="grey-text text-darken-4">${x.text}</span></label></p>`;
            i++;
        });
        template += '</form>';

        template += '<div class="row">';

        template += '<div class="col s12 m3">';
        template += '<button type="button" class="btn waves-effect waves-light" style="width:100%;margin:6px 0px" id="q_reset">Börja om<i class="material-icons left">backspace</i></button>';
        template += '</div>';

        template += '<div class="col s7 m4">';
        template += '<button type="button" class="btn waves-effect waves-light" style="width:100%;margin:6px 0px" id="q_prev">Föregående<i class="material-icons left">navigate_before</i></button>';
        template += '</div>';

        template += '<div class="col s5 m4 offset-m1">';
        template += '<button type="button" class="btn waves-effect waves-light" style="width:100%;margin:6px 0px" id="q_next">Nästa<i class="material-icons right">navigate_next</i></button>';
        template += '</div>';

        template += '</div>';

        return template;
    }

    static result(): string {
        var template = '';

        template += '<table class="highlight">';
        template += '<tr><th>Fråga</th><th>Svar</th><th>Värde</th></tr>';

        let i = 0;
        let factor = 0;
        let points = 0;
        App.store.questions.forEach(x => {
            let opt = App.state.answers[i];
            let answer = x.options[opt];

            if (i == 0) { // First option is factor
                factor = answer.weight;
            }
            else if (i == 1) { // Second option is only one to be multiplied by factor
                points += answer.weight * factor;
            }
            else {
                points += answer.weight;
            }

            template += `<tr><td>${x.title}</td><td>${answer.text}</td><td>${answer.weight}</td></tr>`;

            i++;
        });

        template += `<tr><th></th><th>Poäng</th><th>${points}</th></tr>`;
        template += '</table>';

        template += '<div>';
        template += '<h5 class="center-align">Enligt Skyforest svårighetsindex ligger objektet på svårighet:</h5>';
        template += `<h3 class="center-align">${DifficultyIndex.calculate(points)}</h3>`;
        template += '</div>';

        template += '<div class="row">';

        template += '<div class="col s6 right-align" style="margin-bottom: 10px">';
        template += '<button type="button" class="btn waves-effect waves-light center-align" id="q_reset">Ny beräkning<i class="material-icons left">backspace</i></button>';
        template += '</div>';

        template += '<div class="col s6 left-align">';
        template += '<button type="button" class="btn waves-effect waves-light" id="q_prev">Föregående<i class="material-icons left">navigate_before</i></button>';
        template += '</div>';

        template += '</div>';

        return template;
    }
}