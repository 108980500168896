import { Option, Question } from "./Question"

export class DataStore {
    questions: Question[];

    constructor(questions: Question[]) {
        this.questions = questions;
    }

    static createDefault(): DataStore {
        return new DataStore([
            new Question("Röjningsform"
                , [
                    new Option("Plantskog", 0.7),
                    new Option("1:a röjning", 1.0, true),
                    new Option("2:a röjning", 0.8),
                ]),
            new Question("Täthet, tot antal st/ha"
                , [
                    new Option("< 3000", 5),
                    new Option("3000 - 5000", 15, true),
                    new Option("> 5000", 25),
                ]),
            new Question("Höjd (m) på röjstammar"
                , [
                    new Option("1 - 2", 5),
                    new Option("2 - 4", 15, true),
                    new Option("4 - 6", 25),
                ]),
            new Question("Objektsareal, ha"
                , [
                    new Option("< 2", 12),
                    new Option("2 - 5", 7),
                    new Option("5 - 10", 2, true),
                    new Option("> 10", 0),
                ]),
            new Question("Lutningsklass (hela trakten)"
                , [
                    new Option("1 - 2", 0, true),
                    new Option("3", 3),
                    new Option("4 - 5", 5),
                ]),
            new Question("Ytstruktur"
                , [
                    new Option("1 - 3", 0, true),
                    new Option("4 - 5", 5),
                ]),
            new Question("Avverkningsrester/sten >50 cm"
                , [
                    new Option("< normalt", 0),
                    new Option("normalt", 3, true),
                    new Option("> normalt", 5),
                ]),
            new Question("Gångavstånd till trakt, m (traktmitt)"
                , [
                    new Option("< 200", 0),
                    new Option("200 - 400", 2, true),
                    new Option("400 - 600", 7),
                    new Option("600 - 800", 12),
                    new Option("> 800", 17),
                ]),
        ]);
    }
}