import { App } from "./App";

export function nextQuestion() {
    console.log('Next question requested');
}

window.addEventListener('hashchange', function () {
    App.navigate(window.location.hash);
});

window.addEventListener('load', function () {
    App.init();
    App.navigate(window.location.hash);
});

// Next navigation by space or enter
window.addEventListener('keypress', function (e) {
    if (e.key == ' ' || e.key == 'Enter') {
        let next = document.getElementById('q_next');
        if (next != null) {
            next.click();
        }
    }
    else if (e.key == 'Backspace') {
        let prev = document.getElementById('q_prev');
        if (prev != null) {
            prev.click();
        }
    }
});

// Up/down arrow keys for question selection
window.addEventListener('keydown', function (e) {
    if (e.key == 'ArrowUp') {
        let options = document.getElementsByClassName('option');
        if (options.length > 0) {
            let checkedIndex = 0;
            for (let i = 0; i < options.length; i++) {
                let o = options[i] as HTMLInputElement;
                if (o.checked) {
                    checkedIndex = i;
                }
            }

            if (checkedIndex > 0) {
                (options[checkedIndex-1] as HTMLInputElement).checked = true;
            }
        }
    }
    else if (e.key == 'ArrowDown') {
        let options = document.getElementsByClassName('option');
        if (options.length > 0) {
            let checkedIndex = 0;
            for (let i = 0; i < options.length; i++) {
                let o = options[i] as HTMLInputElement;
                if (o.checked) {
                    checkedIndex = i;
                }
            }

            if (checkedIndex < options.length - 1) {
                (options[checkedIndex+1] as HTMLInputElement).checked = true;
            }
        }
    }
    else if (e.key == 'ArrowRight') {
        let next = document.getElementById('q_next');
        if (next != null) {
            next.click();
        }
    }
    else if (e.key == 'ArrowLeft') {
        let prev = document.getElementById('q_prev');
        if (prev != null) {
            prev.click();
        }
    }
    else if (e.key == 'Delete') {
        let prev = document.getElementById('q_reset');
        if (prev != null) {
            prev.click();
        }
    }
});