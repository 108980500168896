import { App } from "../App";

export class State {
    answers: number[];

    constructor() {
        this.answers = new Array<number>();
    }

    setAnswer(qIdx: number, oIdx: number) {
        console.log('Recorded option ' + oIdx + ' for question ' + qIdx);
        this.answers[qIdx] = oIdx;
    }

    isComplete(): boolean {
        return this.answers.length == App.store.questions.length;
    }
}
