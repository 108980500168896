import { App } from "./App";

export class Navigator {
    static question(i: number) {
        if (i >= App.store.questions.length) {
            window.location.hash = '#result';
            return;
        }

        window.location.hash = '#question/' + (i + 1);
        console.log('Navigated to ' + window.location.hash);
    }
}