import { Navigator } from "./Navigator";

type RouteCallback = (m: RegExpMatchArray) => void;

export class Router {
    paths: [RegExp, RouteCallback][];

    constructor() {
        this.paths = new Array<[RegExp, RouteCallback]>();
    }

    register(path: RegExp, method: RouteCallback): Router {
        this.paths.push([path, method]);
        return this;
    }

    handle(hash: string) {
        let found = false;
        this.paths.forEach(p => {
            if (p[0].test(hash)) {
                p[1](p[0].exec(hash));
                found = true;
            }
        });

        if (!found) {
            this.handle_404();
        }
    }

    private handle_404() {
        console.log('404 hit, redirecting...');
        Navigator.question(0);
    }
}