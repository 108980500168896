export class Question {
    title: string;
    options: Option[];

    constructor(title: string, options: Option[]) {
        this.title = title;
        this.options = options;
    }
}

export class Option {
    text: string;
    weight: number;
    default: boolean;

    constructor(text: string, weight: number, def?: boolean) {
        this.text = text;
        this.weight = weight;
        this.default = def ?? false;
    }
}